:root {
  --color-dark: #1e272e;
  --color-light: white;
  --color-card-red: #c0392b;
}

body {
  margin: 0;
  font-family: Helvetica, sans-serif;
  box-sizing: border-box;
  color: var(--color-dark);
  background-color: var(--color-light);
}

button {
  padding: 3px 8px;
  border: 0;
  background-color: var(--color-dark);
  color: var(--color-light);
  opacity: 1;
  transition: 200ms ease-in-out;
}

button:hover {
  opacity: 0.8;
  cursor: pointer;
}

button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

input {
  border: 1px solid;
}

input:focus {
  outline: none;
}

.centered-screen-box-outer {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.centered-screen-box {
  padding: 30px 5%;
  border: 2px solid;
  font-size: 150%;
}

#join-game-box-button-outer {
  margin-top: 30px;
  text-align: center;
}

#join-game-box-button-outer button {
  font-size: 100%;
  padding: 8px 12px;
}

.join-game-form-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
}

.join-game-input {
  margin-left: 20px;
  font-size: 120%;
}

#waiting-screen .centered-screen-box {
  border: 0;
  padding: 0;
  font-size: 150%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

#waiting-screen button {
  font-size: 100%;
  padding: 8px 12px;
}

#game {
  display: grid;
  grid-template-columns: 3.5fr 1fr;
  height: 100vh;
}

#game-state-section {
  text-align: center;
}

#players-section {
  grid-row: span 2;
  border-left: 2px solid;
  overflow-y: scroll;
}

#players-section h2 {
  padding: 10px;
  text-align: center;
}

.player-details {
  padding: 10px;
  transition: 400ms ease-in-out;
  display: grid;
  grid-template-areas:
    "player-id num-cards"
    "status .";
}

.player-details-current {
  background-color: var(--color-dark);
  color: var(--color-light);
}

.player-details-id {
  font-weight: bold;
  font-size: 120%;
  grid-area: "player-id";
}

.player-details-status {
  font-style: italic;
  grid-area: "status";
}

.player-details-num-cards {
  font-size: 150%;
  justify-self: right;
  grid-area: "num-cards";
}

#card-list-box {
  overflow-y: scroll;
}

#you-section h2 {
  padding-left: 20px;
}

#you-section-pass {
  text-align: center;
  padding-bottom: 30px;
}

#you-section-pass button {
  font-size: 120%;
  padding: 8px 32px;
}

#card-list {
  display: flex;
  flex-wrap: wrap;
  font-size: 800%;
}

.card {
  background-color: var(--color-light);
  line-height: 0.75em;
  height: 0.9em;
}

.top-card {
  opacity: 1;
  font-size: 1200%;
}

.player-card {
  opacity: 0.4;
  cursor: not-allowed;
}

.player-card-playable {
  opacity: 1;
  cursor: pointer;
  transition: 100ms ease-in-out;
}

.card-red {
  color: var(--color-card-red);
}

.card-black {
  color: var(--color-dark);
}

.card-red.player-card-playable:hover {
  color: var(--color-light);
  background-color: var(--color-card-red);
}

.card-black.player-card-playable:hover {
  color: var(--color-light);
  background-color: var(--color-dark);
}

@media (max-width: 600px), (orientation: portrait) {
  #game {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content;
  }

  #game-state-section {
    text-align: center;
  }

  #game-state-section h3 {
    margin: 0;
    padding: 10px 0;
  }

  #game-state-section h2 {
    margin: 0;
    padding: 10px 0;
  }

  #players-section {
    grid-row: span 1;
    overflow-y: visible;
  }

  #players-section h2 {
    margin: 0;
    padding: 5px;
    text-align: center;
  }

  #players-list {
    display: flex;
    flex-direction: column;
  }

  #you-section {
    border-top: 2px solid;
    grid-column: span 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content;
  }

  #card-list-box {
    grid-column: span 2;
  }

  #you-section-pass {
    text-align: center;
    padding: 20px;
  }

  #you-section h2 {
    text-align: center;
    margin: 0;
    padding: 20px;
  }
}
